import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
// import useAuth from '../hooks/useAuth';
// routes
// import { PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default function GuestGuard({ children }) {
    //   const { isAuthenticated } = useAuth();
    // console.log(" guest gaurd calling ......................")
    if (getToken()) {
        return <Navigate to={"/dashboard"} />;
    }

    return <>{children}</>;
}
const getToken = () => sessionStorage.getItem("token") || null
