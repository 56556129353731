import React from "react";
import * as Yup from "yup";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import { useFormik, Form, FormikProvider } from "formik";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import EditorInstance from "@ckeditor/ckeditor5-build-classic";

const editorConfiguration = {
  height: '400px', // Set the desired height
    width: '100%', 
  fontSize: {
    options: [15, 18, 20, 22, 24, 28, 32],
  },
  heading: {
    options: [
      { model: "paragraph", view: "p", title: "Paragraph" },
      { model: "heading1", view: "h1", title: "Heading 1" },
      { model: "heading2", view: "h2", title: "Heading 2" },
      { model: "heading3", view: "h3", title: "Heading 3" },
    ],
  },
  toolbar: {
    items: [
      "alignment",
      "blockQuote",
      "bold",
      "selectAll",
      "undo",
      "redo",
      "fontSize",
      "fontColor",
      "FontBackgroundColor",
      "heading",
      "horizontalLine",
      // 'imageTextAlternative',
      // 'imageUpload',
      // 'indent',
      // 'outdent',
      "italic",
      "link",
      "numberedList",
      "bulletedList",
      // 'mediaEmbed',
      "insertTable",
      "tableColumn",
      "tableRow",
      "mergeTableCells",
    ],
    shouldNotGroupWhenFull: true,
  },
};

// ----------------------------------------------------------------------
function UpdatePolicy(props) {
  // const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
  });

  const formik = useFormik({
    initialValues: {
      title: props.isUpdatePolicy ? props.policyDetails.title : "",
      longDescription: props.isUpdatePolicy
        ? props.policyDetails.longDescription
        : "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      let finalData = {
        title: values.title,
        longDescription: values.longDescription,
      };
      if (props.isUpdatePolicy) {
        props.updatePolicy(
          `policy/update/${props.policyDetails._id}`,
          finalData
        );
      }
    },
  });

  const { errors, touched, handleSubmit } = formik;
  return (
    <FormikProvider value={formik} onSubmit={handleSubmit} >
      <Form autoComplete="off" noValidate >
        <Grid container spacing={3} style={{ margin: "auto"}}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px"  }}
          >
            <CKEditor
              editor={EditorInstance}
              data={formik.values.longDescription}
              onChange={(event, editor) =>
                formik.setFieldValue("longDescription", editor.getData())
              }
              config={editorConfiguration}
              style={editorConfiguration}
            />
            {Boolean(touched.longDescription && errors.longDescription) ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                &nbsp;&nbsp;longDescription Required
              </span>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Button
              style={{
                backgroundColor: "#1d252c",
                color: "white",
                padding: "30px",
                borderRadius: "20px",
              }}
              size="large"
              fullWidth={true}
              type="submit"
            >
              {props.isUpdatePolicy ? "Update" : "Save"}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
function mapStateToProps(state) {
  return {
    state,
  };
}
export default connect(mapStateToProps, {})(UpdatePolicy);
