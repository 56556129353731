import React from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/arrow-back-fill";
import Button from "@material-ui/core/Button";
import { Typography, Grid, Stack, Box } from "@material-ui/core";
// ----------------------------------------------------------------------

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "#f9fafa",
          borderRadius: "20px 0px 0px 20px",
          padding: "20px",
        }}
      >
        <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
              style={{ margin: "auto" }}
            >
              <Button
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "20px",
                }}
                size="large"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.props.handleCloseEventDetails()}
              >
                Event
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ margin: "auto", width: "50%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{
              margin: "10px",
              backgroundColor: "white",
              borderRadius: "20px",
              padding: "20px",
            }}
          >
            <Typography variant="h4">{this.props.eventDetails.title}</Typography>
            <Typography variant="h6">{this.props.eventDetails.date}</Typography>
            <br />
            <table>
            <tbody>
              <tr >
              <td>
              <Box component="img" src={this.props.eventDetails.coverImage}  style={{height: 200}} />
              </td>
              </tr>
              <tr>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.eventDetails.shortDescription,
                  }}
                />
                </td>
              </tr>
              <tr>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.eventDetails.longDescription,
                  }}
                />
                </td>
              </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    state,
  };
}
export default connect(mapStateToProps, {
  // check_project_action
})(EventDetails);
