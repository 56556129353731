import React from "react"
import { connect } from "react-redux"
import { getUserProfile, updateUserProfile } from "../project_actions"
import Button from '@material-ui/core/Button'
import { styled, alpha } from '@material-ui/core/styles';
import {
    Grid,
    Stack,
    InputBase,
    // Pagination,
    CircularProgress,
    Avatar,
    IconButton,
    Alert,
    Snackbar
} from '@material-ui/core';

import 'react-phone-input-2/lib/style.css'
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
// ----------------------------------------------------------------------
const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20,
        position: 'relative',
        backgroundColor: "white",
        border: '0px solid',
        fontSize: 16,
        // width: 'auto',
        padding: '20px 20px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

//-----------------------------------------------------------------------------------
class UserProfile extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            profilePic: "",
            password: "",
            newPassword: "",
            confirmPassword: "",
            isMatchingPassword: true,
            isOpenProfileSetting: true,
            isOpenSecuritySetting: true,
            isOpenSnackbar: false,
            isOpenSnackbar_msg: ""
        }
    }
    UNSAFE_componentWillMount() {
        this.props.getUserProfile("profile")
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.getUserProfile_status === "success" && this.props.getUserProfile_status !== nextProps.getUserProfile_status) {
            if (nextProps.getUserProfile_response.hasOwnProperty("data")) {
                try {
                    this.setState({
                        firstName: nextProps.getUserProfile_response.data.firstName,
                        lastName: nextProps.getUserProfile_response.data.lastName,
                        email: nextProps.getUserProfile_response.data.email,
                        profilePic: nextProps.getUserProfile_response.data.profilePic,
                        password: "",
                        newPassword: "",
                        confirmPassword: ""
                    })
                } catch (e) {
                    console.error(e)
                }
            }
        }
       
        
        if (nextProps.updateUserProfile_status === "success" && nextProps.updateUserProfile_status !== this.props.updateUserProfile_status) {
            this.props.getUserProfile("profile")
        }
        if (nextProps.updateUserProfile_status === "error" && nextProps.updateUserProfile_status !== this.props.updateUserProfile_status) {
            
           this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: nextProps.updateUserProfile_response.message })
        }
    }
    handleBrowseClick = () => {
        document.getElementById("myFile").click()
    }
   
    handleUpdateProfile = () => {
      
        if (this.state.firstName === "") {
            return alert("First name required")
        }  else if (this.state.email === "") {
            return alert("Email required")
        } else if ( this.state.newPassword && this.state.password === "") {
            return alert("Password required")
        }

        if (this.state.newPassword !== this.state.confirmPassword) {
            return alert("The new password and confirmation password do not match.")
        }

        let final_data = {
            "firstName": this.state.firstName,
            "lastName": this.state.lastName
        }
        if (this.state.newPassword !== "") {
            final_data["password"] = this.state.newPassword
            final_data["oldPassword"] = this.state.password
        }
        this.props.updateUserProfile("profile", final_data)
    }
    render() {
        return (
            <div style={{ backgroundColor: "#f9fafa", borderRadius: "20px 0px 0px 20px", padding: "20px" }}>
                <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{ margin: "auto", cursor: "pointer" }} onClick={() => this.setState({ isOpenProfileSetting: !this.state.isOpenProfileSetting })}>
                            <table>
                                <tbody>
                                <tr>
                                    <td>
                                        <IconButton
                                            sx={{
                                                padding: 0,
                                                width: 50,
                                                height: 50,
                                            }}
                                        >
                                            <Avatar src={this.state.profilePic} alt="photoURL" variant="rounded" />
                                        </IconButton>
                                    </td>
                                    </tr>
                                   
                                        <tr>
                                            <td style={{ fontWeight: "bold", color: "#1d252c" }}>{this.state.firstName}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: "#989898" }}>{this.state.email}</td>
                                        </tr>
                                   
                                    </tbody>
                            </table>
                            <Stack display="flex" flexDirection="row">
                                <span>Change Setting </span> &nbsp;{this.state.isOpenProfileSetting ? <ExpandLess /> : <ExpandMore />}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Collapse in={this.state.isOpenProfileSetting} timeout="auto" unmountOnExit>
                    <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                        <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <div style={{ width: "100%" }}>
                                        <div>FIRST NAME</div>
                                        <BootstrapInput
                                            id="bootstrap-input"
                                            // placeholder="Dealer ID"
                                            fullWidth={true}
                                            value={this.state.firstName}
                                            onChange={e => this.setState({ firstName: e.target.value })}
                                        />
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div>LAST NAME</div>
                                        <BootstrapInput
                                            id="bootstrap-input"
                                            // placeholder="Dealer ID"
                                            fullWidth={true}
                                            value={this.state.lastName}
                                            onChange={e => this.setState({ lastName: e.target.value })}
                                        />
                                    </div>
                                </Stack>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <div style={{ width: "100%" }}>
                                        <div>EMAIL</div>
                                        <BootstrapInput
                                            id="bootstrap-input"
                                            placeholder="example@gmail.com"
                                            fullWidth={true}
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value })}
                                        />
                                    </div>
                                    
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Collapse>
                <br />
                <Divider />
                <br />
                <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px", textAlign: "right" }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{ margin: "auto", cursor: "pointer" }} onClick={() => this.setState({ isOpenSecuritySetting: !this.state.isOpenSecuritySetting })}>
                            <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <IconButton
                                            sx={{
                                                padding: 0,
                                                width: 50,
                                                height: 50,
                                            }}
                                        >
                                            <Avatar alt="photoURL" variant="rounded"><LockOutlinedIcon /> </Avatar>
                                        </IconButton>
                                    </td>
                                    <td>
                                        <tr>
                                            <td style={{ fontWeight: "bold", color: "#1d252c", textAlign: "left" }}>Security Settings</td>
                                        </tr>
                                        <tr>
                                            <td style={{ color: "#989898" }}>Create your invoice just few step</td>
                                        </tr>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <Stack display="flex" flexDirection="row">
                                <span>Change Setting </span> &nbsp;{this.state.isOpenSecuritySetting ? <ExpandLess /> : <ExpandMore />}
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Collapse in={this.state.isOpenSecuritySetting} timeout="auto" unmountOnExit>
                    <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                        <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                            <Stack spacing={3}>
                                <div style={{ width: "100%" }}>
                                    <div>CURRENT PASSWORD</div>
                                    <BootstrapInput
                                        id="bootstrap-input"
                                        placeholder="Type your current password here..."
                                        fullWidth={true}
                                        value={this.state.password}
                                        onChange={e => this.setState({ password: e.target.value })}
                                    />
                                </div>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <div style={{ width: "100%" }}>
                                        <div>NEW PASSWORD</div>
                                        <BootstrapInput
                                            id="bootstrap-input"
                                            placeholder="Type new password here..."
                                            fullWidth={true}
                                            value={this.state.newPassword}
                                            onChange={e => this.setState({ newPassword: e.target.value })}
                                        />
                                        {this.state.isMatchingPassword ? null : <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;The new password and confirmation password do not match.</span>}
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div>CONFIRM PASSWORD</div>
                                        <BootstrapInput
                                            id="bootstrap-input"
                                            placeholder="Re-type new password here..."
                                            fullWidth={true}
                                            value={this.state.confirmPassword}
                                            onChange={e => this.setState({ confirmPassword: e.target.value })}
                                        />
                                    </div>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                </Collapse>
                <br />
                <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                    <Grid item xs={12} sm={6} md={3} style={{ margin: "10px", padding: "0px" }}>
                        {
                            this.props.updateUserProfile_status === "started" ? <CircularProgress /> :
                                <Button
                                    style={{ backgroundColor: "#1d252c", color: "white", padding: "30px", borderRadius: "20px" }}
                                    size="large"
                                    type="submit"
                                    fullWidth={true}
                                    onClick={this.handleUpdateProfile}
                                >
                                    Update
                                </Button>
                        }
                    </Grid>
                </Grid>
                <Snackbar
                    open={this.state.isOpenSnackbar}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ isOpenSnackbar: false })}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}>
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {this.state.isOpenSnackbar_msg}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const getUserProfile_status = state.projectReducer.getUserProfile_status
    const getUserProfile_response = state.projectReducer.getUserProfile_response || {}
    const getUserProfile_error = state.projectReducer.getUserProfile_error

    const updateUserProfile_status = state.projectReducer.updateUserProfile_status
    const updateUserProfile_response = state.projectReducer.updateUserProfile_response
    const updateUserProfile_error = state.projectReducer.updateUserProfile_error


    return {
        getUserProfile_status,
        getUserProfile_response,
        getUserProfile_error,

        updateUserProfile_status,
        updateUserProfile_response,
        updateUserProfile_error,

   
    }
}
export default connect(mapStateToProps, {
    getUserProfile,
    updateUserProfile
})(UserProfile)
