import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
// import useAuth from '../hooks/useAuth';
// pages
// import Login from "../pages/Login"
// import { getUser, getToken, setUserSession, removeUserSession } from "src/common"
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
    children: PropTypes.node
};
export default function AuthGuard({ children }) {
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState(null);

    if (!getToken()) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Navigate to={"/login"} />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }
    return <>{children}</>;
}
const getToken = () => sessionStorage.getItem("token") || null
