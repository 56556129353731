import React from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/arrow-back-fill";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { fDate } from "../../utils/formatTime";
import { Avatar } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div style={{ borderRadius: "20px 0px 0px 20px", padding: "20px" }}>
        <Grid container spacing={3} style={{ margin: "auto" }}>
          <Button
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "20px",
            }}
            size="large"
            startIcon={<Icon icon={plusFill} />}
            onClick={() => this.props.handleCloseUserDetails()}
          >
            User
          </Button>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <div>
                <Avatar
                  src={this.props.userDetails.profilePic}
                  alt="photoURL"
                  variant="circular"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "50px",
                  }}
                />
              </div>
              <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    UserName :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.userName}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Account Subscription Type :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {
                      this.props.userDetails?.accountSubscriptionData
                        ?.product_id
                    }
                  </td>
                 
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Email :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.email}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Account Subscription Expire :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails?.accountSubscriptionData
                      ?.planExpireTime
                      ? fDate(
                          this.props.userDetails?.accountSubscriptionData
                            ?.planExpireTime
                        )
                      : ""}
                  </td>
                 
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    First Name :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.firstName}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Business Subscription Type :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {
                      this.props.userDetails?.businessSubscriptionData
                        ?.product_id
                    }
                  </td>
                 
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Created At :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {fDate(this.props.userDetails.createdAt)}
                  </td>

                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Business Subscription Expire :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails?.businessSubscriptionData
                      ?.planExpireTime
                      ? fDate(
                          this.props.userDetails?.accountSubscriptionData
                            ?.planExpireTime
                        )
                      : ""}
                  </td>
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Last Name :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.lastName}
                  </td>
                 
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Company Name :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.companyName
                      ? this.props.userDetails.companyName
                      : "-"}
                  </td>
                  
                </tr>

                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Email Status :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.isEmailVerified
                      ? "Verified"
                      : "Not Verified"}
                  </td>
                  
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    Address :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.streetAddress +
                      " " +
                      this.props.userDetails.suburb +
                      " " +
                      this.props.userDetails.state +
                      " " +
                      this.props.userDetails.postCode}
                  </td>
                 
                </tr>
                <tr>
                  <td
                    style={{
                      fontWeight: "bold",
                      color: "#1d252c",
                      float: "left",
                    }}
                  >
                    User Status :{" "}
                  </td>
                  <td style={{ padding: "0 10px" }}>
                    {this.props.userDetails.isEnActiveByAdmin
                      ? "Active"
                      : "In Active"}
                  </td>             
                </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return { state };
}
export default connect(mapStateToProps, {})(UserDetails);
