import React from "react"
import { Provider } from "react-redux"
import { 
    createStore, 
    // compose, 
    applyMiddleware } from "redux"
// import { syncHistoryWithStore, routerMiddleware } from "react-router-redux"
// import { browserHistory } from "react-router-dom"
import thunk from "redux-thunk"
import Router from './routes';
import ThemeConfig from './theme';
import ScrollToTop from './components/ScrollToTop';
import AppStore from "./project_reducers"

import "./App.css";
// ----------------------------------------------------------------------
const middleware = [thunk]
const store = createStore(AppStore, applyMiddleware(...middleware))
export default function App() {
    return (
        <ThemeConfig>
            <ScrollToTop />
            <Provider store={store}>
                <Router />
            </Provider>
        </ThemeConfig>
    );
}
