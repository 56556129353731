import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@material-ui/core';
// components
import { MHidden } from '../../components/@material-extend';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import NotificationsPopover from './NotificationsPopover';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 260;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
    onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
    let currentPath = window.location.pathname
    const pathNames = currentPath.split("/").filter(x => x)
    return (
        <RootStyle>
            <ToolbarStyle>
                <MHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                        {
                            pathNames.map((pName, index) => {
                                let name=pName[0].toUpperCase() + pName.slice(1)
                                if (index) {
                                    return index === 1 ?
                                        <Typography color="textPrimary" key={index} style={{ fontSize: 20, fontWeight:"bold"}}>{pName==='app'?'Girls Only':name}</Typography>
                                        :
                                        (
                                            // <Link>
                                                <Typography color="textPrimary" style={{ fontSize: 18, }}>{pName}</Typography>
                                            // </Link>
                                        )
                                }
                                return null
                            })
                        }
                    </Breadcrumbs>
                </Stack>
                {/* <Searchbar /> */}
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover /> */}
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}

//https://girlsonly.net.au/wp-content/uploads/2021/09/logo-index-300x238.png