import { ACTION_TYPES } from "../utils"
const initialState = {
    getEvent_status: null,
    getEvent_response: {},
    getEvent_error: null,

    addEvent_status: null,
    addEvent_response: {},
    addEvent_error: null,

    updateEvent_status: null,
    updateEvent_response: {},
    updateEvent_error: null,

    getUserList_status: null,
    getUserList_response: {},
    getUserList_error: null,


    getUserProfile_status: null,
    getUserProfile_response: {},
    getUserProfile_error: null,

    updateUserProfile_status: null,
    updateUserProfile_response: {},
    updateUserProfile_error: null,

    importImage_status: null,
    importImage_response: {},
    importImage_error: null,

    updateUser_status: null,
    updateUser_response: {},
    updateUser_error: null,


    getPolicy_status: null,
    getPolicy_response: {},
    getPolicy_error: null,

    updatePolicy_status: null,
    updatePolicy_response: {},
    updatePolicy_error: null,

}
export default function projectReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.GET_USER_PROFILE: {
            return {
                ...state,
                getUserProfile_status: "started",
            }
        }
        case ACTION_TYPES.GET_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                getUserProfile_status: "success",
                getUserProfile_response: action.payload,
                getUserProfile_error: null,
            }
        }
        case ACTION_TYPES.GET_USER_PROFILE_FAILED: {
            return {
                ...state,
                getUserProfile_status: "error",
                getUserProfile_response: null,
                getUserProfile_error: action.payload,
            }
        }//end
        case ACTION_TYPES.UPDATE_USER_PROFILE: {
            return {
                ...state,
                updateUserProfile_status: "started",
            }
        }
        case ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS: {
            return {
                ...state,
                updateUserProfile_status: "success",
                updateUserProfile_response: action.payload,
                updateUserProfile_error: null,
            }
        }
        case ACTION_TYPES.UPDATE_USER_PROFILE_FAILED: {
            return {
                ...state,
                updateUserProfile_status: "error",
                updateUserProfile_response: null,
                updateUserProfile_error: action.payload,
            }
        }
        //---------------------------------------------------------
        case ACTION_TYPES.IMPORT_IMAGE: {
            return {
                ...state,
                importImage_status: "started",
            }
        }
        case ACTION_TYPES.IMPORT_IMAGE_SUCCESS: {
            return {
                ...state,
                importImage_status: "success",
                importImage_response: action.payload,
                importImage_error: null,
            }
        }
        case ACTION_TYPES.IMPORT_IMAGE_FAILED: {
            return {
                ...state,
                importImage_status: "error",
                importImage_response: null,
                importImage_error: action.payload,
            }
        }
        case ACTION_TYPES.GET_EVENT: {
            return {
                ...state,
                getEvent_status: "started",
                getEvent_response: action.payload,
                getEvent_error: null,
            }
        }
        case ACTION_TYPES.GET_EVENT_SUCCESS: {
            return {
                ...state,
                getEvent_status: "success",
                getEvent_response: action.payload,
                getEvent_error: null,
            }
        }
        case ACTION_TYPES.GET_EVENT_FAILED: {
            return {
                ...state,
                getEvent_status: "error",
                getEvent_response: action.payload,
                getEvent_error: action.payload,
            }
        }



        //------------------------------------------------------------
        case ACTION_TYPES.ADD_EVENT: {
            return {
                ...state,
                addEvent_status: "started",
            }
        }
        case ACTION_TYPES.ADD_EVENT_SUCCESS: {
            return {
                ...state,
                addEvent_status: "success",
                addEvent_response: action.payload,
                addEvent_error: null,
            }
        }
        case ACTION_TYPES.ADD_EVENT_FAILED: {
            return {
                ...state,
                addEvent_status: "error",
                addEvent_response: action.payload,
                addEvent_error: action.payload,
            }
        }


        case ACTION_TYPES.UPDATE_EVENT: {
            return {
                ...state,
                updateEvent_status: "started",
            }
        }
        case ACTION_TYPES.UPDATE_EVENT_SUCCESS: {
            return {
                ...state,
                updateEvent_status: "success",
                updateEvent_response: action.payload,
                updateEvent_error: null,
            }
        }
        case ACTION_TYPES.UPDATE_EVENT_FAILED: {
            return {
                ...state,
                updateEvent_status: "error",
                updateEvent_response: action.payload,
                updateEvent_error: action.payload,
            }
        }
        //-----------------------------------------------------------=
        case ACTION_TYPES.GET_USER_LIST: {
            return {
                ...state,
                getUserList_status: "started",
            }
        }
        case ACTION_TYPES.GET_USER_LIST_SUCCESS: {
            return {
                ...state,
                getUserList_status: "success",
                getUserList_response: action.payload,
                getUserList_error: null,
            }
        }
        case ACTION_TYPES.GET_USER_LIST_FAILED: {
            return {
                ...state,
                getUserList_status: "error",
                getUserList_response: action.payload,
                getUserList_error: null,
            }
        }

        case ACTION_TYPES.UPDATE_USER: {
            return {
                ...state,
                updateUser_status: "started",
            }
        }
        case ACTION_TYPES.UPDATE_USER_SUCCESS: {
            return {
                ...state,
                updateUser_status: "success",
                updateUser_response: action.payload,
                updateUser_error: null,
            }
        }
        case ACTION_TYPES.UPDATE_USER_FAILED: {
            return {
                ...state,
                updateUser_status: "error",
                updateUser_response: action.payload,
                updateUser_error: action.payload,
            }
        }

        ///policy

        case ACTION_TYPES.GET_POLICY: {
            return {
                ...state,
                getPolicy_status: "started",
                getPolicy_response: action.payload,
                getPolicy_error: null,
            }
        }
        case ACTION_TYPES.GET_POLICY_SUCCESS: {
            return {
                ...state,
                getPolicy_status: "success",
                getPolicy_response: action.payload,
                getPolicy_error: null,
            }
        }
        case ACTION_TYPES.GET_POLICY_FAILED: {
            return {
                ...state,
                getPolicy_status: "error",
                getPolicy_response: action.payload,
                getPolicy_error: action.payload,
            }
        }




        case ACTION_TYPES.UPDATE_POLICY: {
            return {
                ...state,
                updatePolicy_status: "started",
            }
        }
        case ACTION_TYPES.UPDATE_POLICY_SUCCESS: {
            return {
                ...state,
                updatePolicy_status: "success",
                updatePolicy_response: action.payload,
                updatePolicy_error: null,
            }
        }
        case ACTION_TYPES.UPDATE_POLICY_FAILED: {
            return {
                ...state,
                updatePolicy_status: "error",
                updatePolicy_response: action.payload,
                updatePolicy_error: action.payload,
            }
        }

        default: {
            return state
        }
    }
}