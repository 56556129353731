// import { Navigate, useRoutes } from 'react-router-dom';
import {
    // BrowserRouter,
    // Routes,
    // Route,
    // Link,
    // Outlet,
    Navigate,
    useRoutes
} from 'react-router-dom'
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';

import NotFound from './pages/Page404';
import EventList from './pages/event/EventList';
import UserList from './pages/user/userList';
import UserProfile from "./pages/UserProfile"
import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';
import TeamList from './pages/user/TeamList';
import PolicyList from './pages/policy/PolicyList';


// ----------------------------------------------------------------------

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <GuestGuard>
                    <LogoOnlyLayout />
                </GuestGuard>
            ),
            children: [
                { path: 'login', element: <Login /> },
                // { path: 'register', element: <Register /> },
                { path: '404', element: <NotFound /> },
                { path: '/', element: <Navigate to="/login" /> },
                { path: '*', element: <Navigate to="/404" /> }
            ]
        },
        {
            path: '/dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { path: 'user', element: <UserList /> },
                { path: 'event', element: <EventList /> },
                { path: 'team', element: <TeamList /> },
                { path: 'profile', element: <UserProfile /> },
                { path: 'policy', element: <PolicyList /> },
                { path: '/dashboard/', element: <Navigate to="/dashboard/user" /> }
            ]
        },
        { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}
