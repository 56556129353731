import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Grid, Autocomplete, TextField } from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import { useFormik, Form, FormikProvider } from "formik";

const optionsForRole = [
  { label: "User", id: "User" },
  { label: "Team", id: "Team" },
];
const optionsForStatus = [
  { label: "InActive", id: "InActive" },
  { label: "Active", id: "Active" },
];

// ----------------------------------------------------------------------
function UpdateUser(props) {
  const [selectedStatus, setSelectedStatus] = useState({
    label: "InActive",
    id: "InActive",
  });
  const [selectedRole, setSelectedRole] = useState({
    label: "User",
    id: "User",
  });

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      let finalData = {
        accountType: selectedRole.id,
        status: selectedStatus.id,
        userId:props.userDetails._id
      };
      props.updateUser(`user/update-user`, finalData)
    },
  });

  useEffect(() => {
    if (!props.userDetails.isEnActiveByAdmin) {
        setSelectedStatus({ label: "Active", id: "Active" })
    } 
    if (props.userDetails.accountType === "Team") {
      setSelectedRole({ label: "Team", id: "Team" });
    } 
  }, [props.userDetails.accountType,props.userDetails.isEnActiveByAdmin]);

  const { errors, touched, handleSubmit } = formik;
  return (
    <FormikProvider value={formik} onSubmit={handleSubmit}>
      <Form autoComplete="off" noValidate>
        <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            email : {props.userDetails.email}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            userName : {props.userDetails.userName}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedRole}
              options={optionsForRole}
              fullWidth={true}
              onChange={(e, newVal) => {
                setSelectedRole(newVal);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Role"
                  onChange={(e) => console.log("ss")}
                />
              )}
            />
            {Boolean(touched.name && errors.name) ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                &nbsp;&nbsp;OEM Id required
              </span>
            ) : null}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedStatus}
              options={optionsForStatus}
              fullWidth={true}
              onChange={(e, newVal) => {
                setSelectedStatus(newVal);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="User Status"
                  onChange={(e) => console.log("ss")}
                />
              )}
            />
            {Boolean(touched.name && errors.name) ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                &nbsp;&nbsp;OEM Id required
              </span>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ margin: "10px", padding: "0px" }}
          >
            <Button
              style={{
                backgroundColor: "#1d252c",
                color: "white",
                padding: "30px",
                borderRadius: "20px",
              }}
              size="large"
              fullWidth={true}
              type="submit"
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
function mapStateToProps(state) {
  return {
    state,
  };
}
export default connect(mapStateToProps, {})(UpdateUser);
