import React from "react"
import { connect } from "react-redux"
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import searchFill from '@iconify/icons-eva/search-fill';
// import Page from '../components/Page';
import { getEventList, addEvent, updateEvent } from "../../project_actions"
// import { propsToClassKey } from "@material-ui/styles"
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import {
    Box,
    // Toolbar,
    Tooltip,
    // IconButton,
    // Typography,
    OutlinedInput,
    InputAdornment,
    Grid,
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    Pagination,
    CircularProgress,
    Alert,
    Snackbar
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EventDetails from "./EventDetails"
import 'react-phone-input-2/lib/style.css'
import AddAndUpdateEvent from "./AddAndUpdateEvent"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';

// ----------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#e9e9e9",
        color: "black",
        fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
        backgroundColor: "#f9fafa",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: "0px solid",
    },
    '&: hover': {
        backgroundColor: "red"
    }
}));
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper ': {
        borderRadius: "25px"
    }
}));
const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPagination-ul': {
        '& .Mui-selected': {
            backgroundColor: "#93D500"
        },
    }
}));
class Event extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isUpdateEvent: false,
            isOpenEvent_details: false,
            name: "",
            email: "",
            contactNumber: "",
            countryCode: "",
            address: "",
            selectedEvent: {},
            query: "",
            pageNumber: 1,
            isOpenSnackbar: false,
            isOpenSnackbar_msg: "",
            isOpenSnackbar_type: "success",
            isDownloading: false,
        }
    }
    UNSAFE_componentWillMount() {
       
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEvent_status === "success" && this.props.addEvent_status !== nextProps.addEvent_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: "Event added successfully", isOpenSnackbar_type: "success" }, () => {
                this.props.getEventList("event/list",{page:1,limit:10})
                this.handleCloseIsOpenDialog()
            })
        }
        if (nextProps.updateEvent_status === "success" && this.props.updateEvent_status !== nextProps.updateEvent_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: "Event updated successfully", isOpenSnackbar_type: "success" }, () => {
                this.props.getEventList("event/list",{page:1,limit:10})
                this.handleCloseIsOpenDialog()
            })
        }
        if (nextProps.addEvent_status === "error" && this.props.addEvent_status !== nextProps.addEvent_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: nextProps.addEvent_response.data.message, isOpenSnackbar_type: "error" })
        }
        if (nextProps.updateEvent_status === "error" && this.props.updateEvent_status !== nextProps.updateEvent_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: nextProps.addEvent_response.data.message, isOpenSnackbar_type: "error" })
        }
       
    
    
    }

    handleOpenEventDetails = (data) => {
        this.setState({ isOpenEvent_details: true, selectedEvent: data })
    }
    handleCloseEventDetails = () => {
        this.setState({ isOpenEvent_details: false })
    }
    handleCloseIsOpenDialog = () => {
        this.setState({ isOpen: false, isUpdateEvent: false, selectedEvent: {} })
    }
    handleInputChange = (e) => {
        this.setState({ query: e.target.value }, () => {
            this.searchSomething(e.target.value);
        })
    }
    searchSomething = query => {
        this.props.getEventList("event/list",{page:1,limit:10, searchString:query})

    };
    componentDidMount() {
        this.searchSomething("")
    }
    handlePagination = (e, page) => {
        this.setState({ pageNumber: page }, () => {
            this.props.getEventList("event/list",{page,limit:10})
        })
    }
   

    handleUpdateEvent = (data) => {
        this.setState({ isOpen: true, selectedEvent: data, isUpdateEvent: true })
    }
   
    render() {
        let pageCount = 1
        if (this.props.getEvent_response.hasOwnProperty("recordsCount")) {
            if (this.props.getEvent_response.recordsCount > 10) {
                let total_count = Math.trunc(Number((this.props.getEvent_response.recordsCount / 10)))
                if (this.props.getEvent_response.recordsCount % 10 !== 0) {
                    total_count = total_count + 1
                }
                pageCount = total_count
            } else {
                pageCount = 1
            }
        }
        return (
            <React.Fragment>
                {
                    this.state.isOpenEvent_details ?
                        <div>
                            <EventDetails
                                handleCloseEventDetails={this.handleCloseEventDetails}
                                eventDetails={this.state.selectedEvent} />
                        </div>
                        :
                        <div style={{ backgroundColor: "#f9fafa", borderRadius: "20px 0px 0px 20px", padding: "20px" }}>
                            {this.props.getEvent_status === "started" && <LinearProgress />}
                            <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                                <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5} style={{ margin: "auto" }}>
                                        <SearchStyle
                                            value={this.state.query}
                                            onChange={this.handleInputChange}
                                            placeholder="Search something..."
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                                                </InputAdornment>
                                            }
                                        />
                                        <div style={{ flexGrow: 1 }}></div>
                                        <Button
                                            style={{ backgroundColor: "black", color: "white", padding: "20px", marginRight: "20px" }}
                                            size="large"
                                            startIcon={<Icon icon={plusFill} />}
                                            onClick={() => this.setState({ isOpen: true })}
                                        >
                                            Add New
                                        </Button>
                                        

                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                                <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                                    {
                                        Object.keys(this.props.getEvent_response_data).length > 0 ?
                                            <TableContainer>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}>Title</StyledTableCell>
                                                            <StyledTableCell>Date</StyledTableCell>
                                                            <StyledTableCell>Member Count</StyledTableCell>
                                                            <StyledTableCell style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}>Action</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            this.props.getEvent_response_data.map((data,index) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell component="th" scope="row" onClick={this.handleOpenEventDetails.bind(this, data)}>{data.title}</StyledTableCell>
                                                                        <StyledTableCell onClick={this.handleOpenEventDetails.bind(this, data)}>{data.date}</StyledTableCell>
                                                                        <StyledTableCell onClick={this.handleOpenEventDetails.bind(this, data)}>{data.memberCount}</StyledTableCell>
                                                                        <StyledTableCell>
                                                                            <Tooltip title="Edit">
                                                                                <EditOutlinedIcon style={{ cursor: "pointer" }} onClick={this.handleUpdateEvent.bind(this, data)} />
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            : null
                                    }
                                    {
                                        this.props.getEvent_status === "started" ?
                                            <div style={{ textAlign: "center" }}>
                                                <CircularProgress />
                                            </div>
                                            : null
                                    }
                                </Grid>
                                {
                                    this.props.getEvent_response_data.length > 0 ?
                                        <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px", textAlign: "right" }}>
                                            <Stack direction="row" alignItems="center" justifyContent="end" mb={5} style={{ margin: "auto" }}>
                                                <StyledPagination
                                                    count={pageCount}
                                                    page={this.state.pageNumber}
                                                    onChange={this.handlePagination}
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                            </Stack>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            <BootstrapDialog
                                open={this.state.isOpen}
                                onClose={() => this.setState({ isOpen: false, isUpdateEvent: false })}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    <div
                                        onClick={() => this.setState({ isOpen: false })}
                                        style={{ padding: "0px", width: "min-content", float: "left", marginRight: "17px", marginLeft: "7px", cursor: "pointer" }}>
                                        <ArrowBackIcon />
                                    </div>
                                    {
                                        this.state.isUpdateEvent ? "Update Event" : "Add Event"
                                    }
                                </DialogTitle>
                                <DialogContent>
                                    <AddAndUpdateEvent
                                        {...this.props}
                                        isOpen={this.state.isOpen}
                                        handleCloseIsOpenDialog={this.handleCloseIsOpenDialog}
                                        eventDetails={this.state.selectedEvent}
                                        isUpdateEvent={this.state.isUpdateEvent}
                                    />
                                </DialogContent>
                            </BootstrapDialog>
                            <Snackbar
                                open={this.state.isOpenSnackbar}
                                autoHideDuration={6000}
                                onClose={() => this.setState({ isOpenSnackbar: false })}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}>
                                <Alert severity={this.state.isOpenSnackbar_type} sx={{ width: '100%' }}>
                                    {this.state.isOpenSnackbar_msg}
                                </Alert>
                            </Snackbar>
                        </div>
                }
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    const getEvent_response_temp = state.projectReducer.getEvent_response || {}
    let getEvent_response = {}
    let getEvent_response_data = []
    if (getEvent_response_temp.hasOwnProperty("data")) {
        getEvent_response = JSON.parse(JSON.stringify(getEvent_response_temp.data))
        if (getEvent_response_temp.data.hasOwnProperty("records"))
            getEvent_response_data = getEvent_response_temp.data.records || []
    }
    const getEvent_status = state.projectReducer.getEvent_status

    const addEvent_status = state.projectReducer.addEvent_status
    const addEvent_response = state.projectReducer.addEvent_response || {}


    const updateEvent_status = state.projectReducer.updateEvent_status
    const updateEvent_response = state.projectReducer.updateEvent_response
    const updateEvent_error = state.projectReducer.updateEvent_error
    return {
        getEvent_response,
        getEvent_response_data,

        getEvent_status,
        addEvent_status,
        addEvent_response,

        updateEvent_status,
        updateEvent_response,
        updateEvent_error
    };
}
export default connect(mapStateToProps, {
    getEventList,
    addEvent, 
    updateEvent
})(Event)
