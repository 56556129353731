import React from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { getUserList,updateUser } from "../../project_actions";
import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { tableCellClasses } from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UpdateUser from "./UpdateUser";


import {
  Box,
  OutlinedInput,
  InputAdornment,
  Grid,
  Stack,
  Pagination,
  Alert,
  Snackbar,
  Tooltip,
  Dialog,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import "react-phone-input-2/lib/style.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import UserDetails from "./UserDetails";

// ----------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e9e9e9",
    color: "black",
    fontSize: 18,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18,
    backgroundColor: "#f9fafa",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: "0px solid",
  },
  "&: hover": {
    backgroundColor: "red",
  },
}));
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
      padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
      padding: theme.spacing(1),
  },
  '& .MuiDialog-paper ': {
      borderRadius: "25px"
  }
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    "& .Mui-selected": {
      backgroundColor: "#93D500",
    },
  },
}));
class UserList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdateUser:false,
      isOpen: false,
      name: "",
      email: "",
      contactNumber: "",
      countryCode: "",
      address: "",
      query: "",
      pageNumber: 1,
      isOpenSnackbar: false,
      isOpenSnackbar_msg: "",
      isOpenSnackbar_type: "success",
      anchorEl_filter: null,
      isFilterApplied: false,
      selectedUser:{},
      isOpenUser_details: false,
    };
  }
  UNSAFE_componentWillMount() {
    this.props.getUserList("user/list", {
      page: 1,
      limit: 10,
      userType: "User",
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
 
    if (nextProps.updateUser_status === "success" && this.props.updateUser_status !== nextProps.updateUser_status) {
        this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: "User updated successfully", isOpenSnackbar_type: "success" }, () => {
            this.props.getUserList("user/list",{
              page: 1,
              limit: 10,
              userType: "User",
            })
            this.handleCloseIsOpenDialog()
        })
    }
   
    if (nextProps.updateUser_status === "error" && this.props.updateUser_status !== nextProps.updateUser_status) {
        this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: nextProps.addEvent_response.data.message, isOpenSnackbar_type: "error" })
    }
}

  handleCloseIsOpenDialog = () => {
    this.setState({
      isOpen: false,
      isUpdateUser: false,
    });
  };
  handleInputChange = (e) => {
    this.setState({ query: e.target.value }, () => {
      this.searchSomething(e.target.value);
    });
  };

  handleOpenUserDetails = async(data) => {
    this.setState({ isOpenUser_details: true, selectedUser: data })
    }
  handleCloseUserDetails = () => {
    this.setState({ isOpenUser_details: false  })
   }

  searchSomething = (query) => {
    this.props.getUserList("user/list", {
      page: 1,
      limit: 10,
      searchString: query,
      userType: "User",
    });
  };
  componentDidMount() {
    this.searchSomething("");
  }

  handleUpdateUser = (data) => {
    this.setState({ isOpen: true, selectedUser: data, isUpdateUser: true })
}

  dateConversion = (secs) => {
    let date = new Date(secs);
    return date.toLocaleString();
  };

  handlePagination = (e, page) => {
    this.setState({ pageNumber: page }, () => {
      this.props.getUserList("user/list", {
        page,
        limit: 10,
        userType: "User",
      });
    });
  };

  render() {
    let pageCount = 0;
    let { pageNumber } = this.state;
    if (this.props.getUserList_response.hasOwnProperty("recordsCount")) {
      if (this.props.getUserList_response.recordsCount > 10) {
        let total_count = Math.trunc(
          Number((this.props.getUserList_response.recordsCount / 10).toFixed(0))
        );
        if (this.props.getUserList_response.recordsCount % 10 !== 0) {
          total_count = total_count + 1;
        }
        pageCount = total_count;
      } else {
        pageCount = 1;
        pageNumber = 1;
      }
    }

    return (
      <React.Fragment>
        {
          this.state.isOpenUser_details ?
          <div>
              <UserDetails
                  handleCloseUserDetails={this.handleCloseUserDetails}
                  userDetails={this.state.selectedUser} />
          </div>
          :
          <div
            style={{
              backgroundColor: "#f9fafa",
              borderRadius: "20px 0px 0px 20px",
              padding: "20px",
            }}
          >
            {this.props.getUserList_status === "started" && <LinearProgress />}
            <Grid
              container
              spacing={3}
              style={{ margin: "auto", width: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ margin: "10px", padding: "0px" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={5}
                  style={{ margin: "auto" }}
                >
                  <SearchStyle
                    value={this.state.query}
                    onChange={this.handleInputChange}
                    placeholder="Search something..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Box
                          component={Icon}
                          icon={searchFill}
                          sx={{ color: "text.disabled" }}
                        />
                      </InputAdornment>
                    }
                  />
                  <div style={{ flexGrow: 1 }}></div>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ margin: "auto", width: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ margin: "10px", padding: "0px" }}
              >
                {Object.keys(this.props.getUserList_response).length > 0 ? (
                  <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>User Name</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Status</StyledTableCell>
                          <StyledTableCell>Subscripton Type</StyledTableCell>
                         
                          <StyledTableCell>
                          Business Subscripton Type
                          </StyledTableCell>
                          <StyledTableCell style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.getUserList_response_data.map(
                          (data, index) => {
                            let userName = "";

                            let email = "";
                            let accountSubscriptionType = "";
                            let accountSubscriptionExpire;
                            let businessSubscriptionType = "";
                            let businessSubscriptionExpire;
                            let active = "Active";
                            if (data.hasOwnProperty("userName"))
                              userName = data.userName;
                            if (data.hasOwnProperty("isEnActiveByAdmin")) {
                              active = data.isEnActiveByAdmin
                                ? "InActive"
                                : "Active";
                            }

                            if (data.hasOwnProperty("email"))
                              email = data.email;
                            if (
                              data.hasOwnProperty("accountSubscriptionData")
                            ) {
                              if (
                                data.accountSubscriptionData.hasOwnProperty(
                                  "planExpireTime"
                                )
                              ) {
                                accountSubscriptionExpire = this.dateConversion(
                                  data.accountSubscriptionData.planExpireTime
                                );
                              }
                              if (
                                data.accountSubscriptionData.hasOwnProperty(
                                  "product_id"
                                )
                              ) {
                                accountSubscriptionType =
                                  data.accountSubscriptionData.product_id;
                              }
                            }

                            if (
                              data.hasOwnProperty("businessSubscriptionData")
                            ) {
                              if (
                                data.businessSubscriptionData.hasOwnProperty(
                                  "planExpireTime"
                                )
                              ) {
                                businessSubscriptionExpire =
                                  this.dateConversion(
                                    data.businessSubscriptionData.planExpireTime
                                  );
                              }
                              if (
                                data.businessSubscriptionData.hasOwnProperty(
                                  "product_id"
                                )
                              ) {
                                businessSubscriptionType =
                                  data.businessSubscriptionData.product_id;
                              }
                            }

                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell onClick={this.handleOpenUserDetails.bind(this, data)}>{userName}</StyledTableCell>

                                <StyledTableCell onClick={this.handleOpenUserDetails.bind(this, data)}>{email}</StyledTableCell>
                                <StyledTableCell onClick={this.handleOpenUserDetails.bind(this, data)}>{active}</StyledTableCell>
                                <StyledTableCell onClick={this.handleOpenUserDetails.bind(this, data)}>
                                  {accountSubscriptionType +"\n"}
                                  {accountSubscriptionExpire}
                                </StyledTableCell>
                              

                                <StyledTableCell onClick={this.handleOpenUserDetails.bind(this, data)}>
                                  {businessSubscriptionType +"\n"}{businessSubscriptionExpire}
                                </StyledTableCell>

                                <StyledTableCell>
                                                                            <Tooltip title="Edit">
                                                                                <EditOutlinedIcon style={{ cursor: "pointer" }}  onClick={this.handleUpdateUser.bind(this, data)}/>
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                               
                              </StyledTableRow>
                            );
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                style={{ margin: "10px", padding: "0px", textAlign: "right" }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="end"
                  mb={5}
                  style={{ margin: "auto" }}
                >
                  <StyledPagination
                    count={pageCount}
                    page={pageNumber}
                    variant="outlined"
                    shape="rounded"
                    onChange={this.handlePagination}
                  />
                </Stack>
              </Grid>
            </Grid>
          </div>
        }
        
        <BootstrapDialog
                                open={this.state.isOpen}
                                onClose={() => this.setState({ isOpen: false, isUpdateEvent: false })}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    <div
                                        onClick={() => this.setState({ isOpen: false })}
                                        style={{ padding: "0px", width: "min-content", float: "left", marginRight: "17px", marginLeft: "7px", cursor: "pointer" }}>
                                        <ArrowBackIcon />
                                    </div>
                                    Update User
                                </DialogTitle>
                                <DialogContent className="dialogContentInternal">
                                    <UpdateUser
                                        {...this.props}
                                        isOpen={this.state.isOpen}
                                        handleCloseIsOpenDialog={this.handleCloseIsOpenDialog}
                                        userDetails={this.state.selectedUser}
                                        isUpdateUser={this.state.isUpdateUser}
                                    />
                                </DialogContent>
                            </BootstrapDialog>
        <Snackbar
          open={this.state.isOpenSnackbar}
          autoHideDuration={6000}
          onClose={() => this.setState({ isOpenSnackbar: false })}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            severity={this.state.isOpenSnackbar_type}
            sx={{ width: "100%" }}
          >
            {this.state.isOpenSnackbar_msg}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  //-----------------------------------------
  const getUserList_response_temp =
    state.projectReducer.getUserList_response || {};

  let getUserList_response = {};
  let getUserList_response_data = [];

  if (getUserList_response_temp.hasOwnProperty("data")) {
    getUserList_response = JSON.parse(
      JSON.stringify(getUserList_response_temp.data)
    );
    if (getUserList_response_temp.data.hasOwnProperty("records")) {
      getUserList_response_data = getUserList_response_temp.data.records;
    }
  }
  const getUserList_status = state.projectReducer.getUserList_status;
  //---------------------------


  const updateUser_status = state.projectReducer.updateUser_status
  const updateUser_response = state.projectReducer.updateUser_response
  const updateUser_error = state.projectReducer.updateUser_error

  return {
    getUserList_response_data,
    getUserList_status,
    getUserList_response,

    updateUser_status,
    updateUser_response,
    updateUser_error
  };
}
export default connect(mapStateToProps, {
  getUserList,
  updateUser
})(UserList);
