import React from "react"
import * as Yup from 'yup';
import { connect } from "react-redux"
import Button from '@material-ui/core/Button'
import { styled, alpha } from '@material-ui/core/styles';
import {
    Grid,
    // Container,
    // Stack,
    // Dialog,
    InputBase,
} from '@material-ui/core';
import 'react-phone-input-2/lib/style.css'
import { useFormik, Form, FormikProvider } from 'formik'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import EditorInstance from '@ckeditor/ckeditor5-build-classic';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 20,
        position: 'relative',
        backgroundColor: "#f9fafa",
        border: '0px solid',
        fontSize: 16,
        // width: 'auto',
        padding: '20px 20px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));


const editorConfiguration = {
        fontSize:{
            options:[15,18,20,22,24,28,32]
        },
        heading:{
            options: [
                { model: 'paragraph',view: 'p', title: 'Paragraph'},
                { model: 'heading1', view: 'h1', title: 'Heading 1'},
                { model: 'heading2', view: 'h2', title: 'Heading 2'},
                { model: 'heading3', view: 'h3', title: 'Heading 3'},
            ]
        },
        toolbar: {
            items: [
                'alignment',
                'blockQuote',
                'bold',
                'selectAll',
                'undo',
                'redo',
                'fontSize',
                "fontColor",
                'FontBackgroundColor',
                'heading',
                'horizontalLine',
                // 'imageTextAlternative',
                // 'imageUpload',
                // 'indent',
                // 'outdent',
                'italic',
                'link',
                'numberedList',
                'bulletedList',
                // 'mediaEmbed',
                'insertTable',
                'tableColumn',
                'tableRow',
                'mergeTableCells',
            ],
            shouldNotGroupWhenFull: true,
        },
    };


// ----------------------------------------------------------------------
function AddAndUpdateEvent(props) {
    // const [showPassword, setShowPassword] = useState(false);


    const RegisterSchema = Yup.object().shape({
        title: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('First name required'),
    });

    const formik = useFormik({
        initialValues: {
            title: props.isUpdateEvent ? props.eventDetails.title : '',
            date: props.isUpdateEvent ? props.eventDetails.date : '',
            coverImage: props.isUpdateEvent ? props.eventDetails.coverImage : '',
            shortDescription: props.isUpdateEvent ? props.eventDetails.shortDescription : '',
            longDescription: props.isUpdateEvent ? props.eventDetails.longDescription : ''
        },
        validationSchema: RegisterSchema,
        onSubmit: (values) => {
            let finalData = {
                "title": values.title,
                "date": values.date,
                "coverImage": values.coverImage,
                "shortDescription": values.shortDescription,
                "longDescription": values.longDescription
            }
            if (props.isUpdateEvent) {
                props.updateEvent(`event/update/${props.eventDetails._id}`, finalData)
            } else {
                props.addEvent("event/add", finalData)
            }

        }
    });


    const { errors, touched, handleSubmit, getFieldProps } = formik;
    return (
        <FormikProvider value={formik} onSubmit={handleSubmit}>
            <Form autoComplete="off" noValidate>
                <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        <BootstrapInput
                            id="bootstrap-input"
                            placeholder="Enter Title"
                            fullWidth={true}
                            {...getFieldProps('title')}
                        />
                        {Boolean(touched.title && errors.title) ? <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;Title required</span> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        <BootstrapInput
                            id="bootstrap-input"
                            placeholder="Enter Date"
                            fullWidth={true}
                            {...getFieldProps('date')}
                        />
                        {Boolean(touched.date && errors.date) ? <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;Date Required</span> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        <BootstrapInput
                            id="bootstrap-input"
                            placeholder="Enter Cover Image url"
                            fullWidth={true}
                            {...getFieldProps('coverImage')}
                        />
                        {Boolean(touched.coverImage && errors.coverImage) ? <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;coverImage url Required</span> : null}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        {/* <BootstrapInput
                            id="bootstrap-input"
                            placeholder="Enter shortDescription"
                            fullWidth={true}
                            {...getFieldProps('shortDescription')}
                        /> */}
                        <p>Enter Short Description</p>
                         <CKEditor
                            editor={EditorInstance}
                            data={formik.values.shortDescription}
                            onChange={(event,editor) => formik.setFieldValue('shortDescription', editor.getData())}
                            config={editorConfiguration}
                        /> 
                        {Boolean(touched.shortDescription && errors.shortDescription) ? <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;shortDescription Required</span> : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        {/* <BootstrapInput
                            id="bootstrap-input"
                            placeholder="Enter longDescription"
                            fullWidth={true}
                            {...getFieldProps('longDescription')}
                        /> */}
                        {/* <EditorController
                            id="bootstrap-input"
                            placeholder="Enter longDescription"
                            {...getFieldProps('longDescription')}
                        /> */}
                         <p>Enter Long Description</p>
                     <CKEditor
                            editor={EditorInstance}
                            data={formik.values.longDescription}
                            onChange={(event,editor) => formik.setFieldValue('longDescription', editor.getData())}
                            config={editorConfiguration}
                        /> 
                        {Boolean(touched.longDescription && errors.longDescription) ? <span style={{ color: "red", fontSize: "12px" }}>&nbsp;&nbsp;longDescription Required</span> : null}
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                        <Button
                            style={{ backgroundColor: "#1d252c", color: "white", padding: "30px", borderRadius: "20px" }}
                            size="large"
                            fullWidth={true}
                            type="submit"
                        >
                            {
                                props.isUpdateEvent ? "Update" : "Save"
                            }
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    );
}
function mapStateToProps(state) {
    return {
        state
    };
}
export default connect(mapStateToProps, {
})(AddAndUpdateEvent)
