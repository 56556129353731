import React from "react"
import { connect } from "react-redux"
import { getPolicyList, updatePolicy } from "../../project_actions"
import { styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell, { tableCellClasses } from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
    Tooltip,
    Grid,
    Stack,
    Dialog,
    DialogContent,
    DialogTitle,
    Pagination,
    CircularProgress,
    Alert,
    Snackbar
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PolicyDetails from "./PolicyDetails"
import 'react-phone-input-2/lib/style.css'
import UpdatePolicy from "./UpdatePolicy"
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LinearProgress from '@material-ui/core/LinearProgress';

// ----------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#e9e9e9",
        color: "black",
        fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 18,
        backgroundColor: "#f9fafa",
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:last-child td, &:last-child th': {
        border: "0px solid",
    },
    '&: hover': {
        backgroundColor: "red"
    }
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper ': {
        borderRadius: "25px"
    }
}));
const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPagination-ul': {
        '& .Mui-selected': {
            backgroundColor: "#93D500"
        },
    }
}));
class Policy extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            isUpdatePolicy: false,
            isOpenPolicy_details: false,
            name: "",
            email: "",
            contactNumber: "",
            countryCode: "",
            address: "",
            selectedPolicy: {},
            query: "",
            pageNumber: 1,
            isOpenSnackbar: false,
            isOpenSnackbar_msg: "",
            isOpenSnackbar_type: "success",
            isDownloading: false,
        }
    }
    UNSAFE_componentWillMount() {
       
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      
        if (nextProps.updatePolicy_status === "success" && this.props.updatePolicy_status !== nextProps.updatePolicy_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: "Policy updated successfully", isOpenSnackbar_type: "success" }, () => {
                this.props.getPolicyList("policy/list",{page:1,limit:10})
                this.handleCloseIsOpenDialog()
            })
        }
        if (nextProps.updatePolicy_status === "error" && this.props.updatePolicy_status !== nextProps.updatePolicy_status) {
            this.setState({ isOpenSnackbar: true, isOpenSnackbar_msg: nextProps.updatePolicy_status.data.message, isOpenSnackbar_type: "error" })
        }
       
    
    
    }
    searchSomething = query => {
        this.props.getPolicyList("policy/list",{page:1,limit:10, searchString:query})

    };
    componentDidMount() {
        this.searchSomething("")
    }
    handleOpenPolicyDetails = (data) => {
        this.setState({ isOpenPolicy_details: true, selectedPolicy: data })
    }
    handleClosePolicyDetails = () => {
        this.setState({ isOpenPolicy_details: false })
    }
    handleCloseIsOpenDialog = () => {
        this.setState({ isOpen: false, isUpdatePolicy: false, selectedPolicy: {} })
    }
   
    handlePagination = (e, page) => {
        this.setState({ pageNumber: page }, () => {
            this.props.getPolicyList("policy/list",{page,limit:10})
        })
    }
   

    handleUpdatePolicy = (data) => {
        this.setState({ isOpen: true, selectedPolicy: data, isUpdatePolicy: true })
    }
   
    render() {
        let pageCount = 1
        if (this.props.getPolicy_response.hasOwnProperty("recordsCount")) {
            if (this.props.getPolicy_response.recordsCount > 10) {
                let total_count = Math.trunc(Number((this.props.getPolicy_response.recordsCount / 10)))
                if (this.props.getPolicy_response.recordsCount % 10 !== 0) {
                    total_count = total_count + 1
                }
                pageCount = total_count
            } else {
                pageCount = 1
            }
        }
        return (
            <React.Fragment>
                {
                    this.state.isOpenPolicy_details ?
                        <div>
                            <PolicyDetails
                                handleClosePolicyDetails={this.handleClosePolicyDetails}
                                policyDetails={this.state.selectedPolicy} />
                        </div>
                        :
                        <div style={{ backgroundColor: "#f9fafa", borderRadius: "20px 0px 0px 20px", padding: "20px" }}>
                            {this.props.getPolicy_status === "started" && <LinearProgress />}
                           
                            <Grid container spacing={3} style={{ margin: "auto", width: "100%" }}>
                                <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px" }}>
                                    {
                                        Object.keys(this.props.getPolicy_response_data).length > 0 ?
                                            <TableContainer>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell style={{ borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px" }}>Title</StyledTableCell>
                                                           
                                                            <StyledTableCell style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px" }}>Action</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            this.props.getPolicy_response_data.map((data,index) => {
                                                                return (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell component="th" scope="row" onClick={this.handleOpenPolicyDetails.bind(this, data)}>{data.title}</StyledTableCell>
                                                                    
                                                                        <StyledTableCell>
                                                                            <Tooltip title="Edit">
                                                                                <EditOutlinedIcon style={{ cursor: "pointer" }} onClick={this.handleUpdatePolicy.bind(this, data)} />
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                )
                                                            })
                                                        }

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            : null
                                    }
                                    {
                                        this.props.getPolicy_status === "started" ?
                                            <div style={{ textAlign: "center" }}>
                                                <CircularProgress />
                                            </div>
                                            : null
                                    }
                                </Grid>
                                {
                                    this.props.getPolicy_response_data.length > 0 ?
                                        <Grid item xs={12} sm={12} md={12} style={{ margin: "10px", padding: "0px", textAlign: "right" }}>
                                            <Stack direction="row" alignItems="center" justifyContent="end" mb={5} style={{ margin: "auto" }}>
                                                <StyledPagination
                                                    count={pageCount}
                                                    page={this.state.pageNumber}
                                                    onChange={this.handlePagination}
                                                    variant="outlined"
                                                    shape="rounded"
                                                />
                                            </Stack>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            <BootstrapDialog
                                open={this.state.isOpen}
                                onClose={() => this.setState({ isOpen: false, isUpdatePolicy: false })}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    <div
                                        onClick={() => this.setState({ isOpen: false })}
                                        style={{ padding: "0px", width: "min-content", float: "left", marginRight: "17px", marginLeft: "7px", cursor: "pointer" }}>
                                        <ArrowBackIcon />
                                    </div>
                                    {
                                       "Update "+this.state.selectedPolicy.title
                                    }
                                </DialogTitle>
                                <DialogContent>
                                    <UpdatePolicy
                                        {...this.props}
                                        isOpen={this.state.isOpen}
                                        handleCloseIsOpenDialog={this.handleCloseIsOpenDialog}
                                        policyDetails={this.state.selectedPolicy}
                                        isUpdatePolicy={this.state.isUpdatePolicy}
                                    />
                                </DialogContent>
                            </BootstrapDialog>
                            <Snackbar
                                open={this.state.isOpenSnackbar}
                                autoHideDuration={6000}
                                onClose={() => this.setState({ isOpenSnackbar: false })}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}>
                                <Alert severity={this.state.isOpenSnackbar_type} sx={{ width: '100%' }}>
                                    {this.state.isOpenSnackbar_msg}
                                </Alert>
                            </Snackbar>
                        </div>
                }
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    const getPolicy_response_temp = state.projectReducer.getPolicy_response || {}
    let getPolicy_response = {}
    let getPolicy_response_data = []
    if (getPolicy_response_temp.hasOwnProperty("data")) {
        getPolicy_response = JSON.parse(JSON.stringify(getPolicy_response_temp.data))
        if (getPolicy_response_temp.data.hasOwnProperty("records"))
        getPolicy_response_data = getPolicy_response_temp.data.records || []
    }

    const getPolicy_status = state.projectReducer.getPolicy_status

    


    const updatePolicy_status = state.projectReducer.updatePolicy_status
    const updatePolicy_response = state.projectReducer.updatePolicy_response
    const updatePolicy_error = state.projectReducer.updatePolicy_error
    
    return {
        getPolicy_status,
        getPolicy_response,
        getPolicy_response_data,

    
        updatePolicy_status,
        updatePolicy_response,
        updatePolicy_error
    };
}
export default connect(mapStateToProps, {
    getPolicyList, 
    updatePolicy
})(Policy)
