import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return <Box component="img" src="https://girlsonly.net.au/wp-content/uploads/2021/09/logo-index-300x238.png" sx={{ height: 60, ...sx }} />;
}
