import * as Yup from "yup";
import axios from "axios";
import { useState } from "react";
import {
  //  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  // Link,
  Stack,
  // Checkbox,
  // TextField,
  IconButton,
  InputAdornment,
  // FormControlLabel,
  InputBase,
  Button,
  Alert,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
// import { LoadingButton } from '@material-ui/lab';
// import { setUserSession } from '../../../common'
import { styled, alpha } from "@material-ui/core/styles";
import { URL } from "../../../utils/constants";
// ----------------------------------------------------------------------

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 20,
    position: "relative",
    backgroundColor: "#f9fafa",
    border: "0px solid",
    fontSize: 16,
    // width: 'auto',
    padding: "20px 20px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
export default function LoginForm() {
  const navigate = useNavigate();
  const [showProgress, setProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginResponse, setLoginResponse] = useState([]);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      let login_data = {
        email: values.email,
        password: values.password,
      };
      axios
        .post(URL + "login", login_data)
        .then((response) => {
          let user_data = {
            contactNumber: response.data.data.contactNumber,
            countryCode: response.data.data.countryCode,
            email: response.data.data.email,
            firstName: response.data.data.firstName,
            lastName: response.data.data.lastName,
            profilePic: response.data.data.profilePic,
          };
          sessionStorage.setItem("token", response.data.data.deviceToken);
          sessionStorage.setItem("USER_DATA", JSON.stringify(user_data));
          setProgress(false);
          setLoginError(false);
          navigate("/dashboard/app", { replace: true });
        })
        .catch((error) => {
          if (error.response) {
            setLoginError(true);
            setProgress(false);
            setLoginResponse(error.response.data);
          }
        });
    },
  });

  const {
    errors,
    touched,
    // values,
    // isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <>
      <Snackbar
        open={loginError}
        autoHideDuration={6000}
        onClose={() => setLoginError(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {loginResponse.message === "passwordNotMatched"
            ? "Password does not match Please re-enter a new password"
            : null}
          {loginResponse.message === "teamMemberNotExist"
            ? "This team member does not exist"
            : null}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <div style={{}}>Email</div>
            <BootstrapInput
              id="bootstrap-input"
              autoComplete="username"
              placeholder="Enter Your ID"
              fullWidth={true}
              {...getFieldProps("email")}
            />
            {Boolean(touched.email && errors.email) ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                &nbsp;&nbsp;Email be a valid
              </span>
            ) : null}
            <div>Password</div>
            <BootstrapInput
              id="bootstrap-input"
              placeholder="Enter Your Password"
              autoComplete="current-password"
              fullWidth={true}
              type="password"
              {...getFieldProps("password")}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {Boolean(touched.password && errors.password) ? (
              <span style={{ color: "red", fontSize: "12px" }}>
                &nbsp;&nbsp;Password is required
              </span>
            ) : null}
          </Stack>
          <br />
          <br />
          <Button
            style={{
              backgroundColor: "#1d252c",
              color: "white",
              padding: "30px",
              borderRadius: "20px",
            }}
            size="large"
            fullWidth={true}
            type="submit"
            onClick={() => setProgress(true)}
          >
            {showProgress ? (
              <div style={{ width: "100%", textAlign: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              "Log In"
            )}
          </Button>
          <br />
        </Form>
      </FormikProvider>
    </>
  );
}
