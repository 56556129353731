import peopleFill from '@iconify/icons-eva/people-fill';
import { Icon } from '@iconify/react';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


const sidebarConfig = [
    {
        title: 'users',
        path: '/dashboard/user',
        icon:  getIcon(peopleFill)
       
    },
    {
        title: 'events',
        path: '/dashboard/event',
        icon: getIcon(fileTextFill)
    },
    {
        title: 'Team',
        path: '/dashboard/team',
        icon:  getIcon(peopleFill)
       
    },
    {
        title: 'policy',
        path: '/dashboard/policy',
        icon: getIcon(fileTextFill)
    },
];

export default sidebarConfig;
