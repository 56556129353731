import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
// import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/power-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@material-ui/core/styles';
import { Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@material-ui/core';
// components
import MenuPopover from '../../components/MenuPopover';
//
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    //   {
    //     label: 'Home',
    //     icon: homeFill,
    //     linkTo: '/'
    //   },
    {
        label: 'Profile',
        icon: personFill,
        linkTo: '/dashboard/profile',
        color: "black"
    },
    {
        label: 'Logout',
        icon: settings2Fill,
        linkTo: '/login',
        color: "red"
    }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (option) => {
        if (option.label === "Logout") {
            sessionStorage.clear()
        }
        setOpen(false);
    };
    let User_data = {}
    let User_Img = null
    if(sessionStorage.getItem("USER_DATA")){
        User_data = JSON.parse(sessionStorage.getItem("USER_DATA"))
        if(User_data.hasOwnProperty("profilePic"))
            User_Img = User_data.profilePic
    }
    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
                        }
                    })
                }}
            >
                <Avatar src={User_Img ? User_Img : account.photoURL} alt="photoURL" />
            </IconButton>
            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {User_data.firstName + User_data.lastName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {User_data.email}
                    </Typography>
                </Box> 

                <Divider sx={{ my: 1 }} />

                {
                    MENU_OPTIONS.map((option) => {
                        return (
                            <MenuItem
                                key={option.label}
                                to={option.linkTo}
                                component={RouterLink}
                                onClick={() => handleClose(option)}
                                sx={{ typography: 'body2', py: 1, px: 2.5 }}
                            >
                                <Box
                                    component={Icon}
                                    icon={option.icon}
                                    color={option.color}
                                    sx={{
                                        mr: 2,
                                        width: 24,
                                        height: 24
                                    }}
                                />
                                <span style={{ color: option.color }}>
                                    {option.label}
                                </span>
                            </MenuItem>
                        )
                    })
                }

                {/* <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth color="inherit" variant="outlined">
                        Logout
                    </Button>
                </Box> */}
            </MenuPopover>
        </>
    );
}
