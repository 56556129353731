import axios from "axios"
import { ACTION_TYPES } from "../utils"
import { URL} from "../utils/constants"

const makeAxiosCall = (method, url_path, data = {}, mediaType = null) => {
    var url = `${URL}${url_path}`
    var headers = {
        "x-auth-token": sessionStorage.getItem("token")
    }
    if (method === "post" ) {
        if (mediaType === "file") {
            headers["accept"] = "text/html"
            headers["content-type"] = "multipart/form-data"
        }
    }
    let authOptions = {
        method,
        url,
        data,
        headers
    }
    return axios(authOptions)
        .then((response) => {
            return { error: null, response: response, success: true }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    sessionStorage.clear()
                    window.location.replace("/login")
                }
                return { error: error, response: error.response.data, success: false }
            }
        })
}
//-------------------------------------------------------------------------------------
export const getEventList = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.GET_EVENT,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("post", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.GET_EVENT_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.GET_EVENT_FAILED,
                payload: error.response,
            })
        }
    }
}
export const addEvent = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.ADD_EVENT,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("post",url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.ADD_EVENT_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.ADD_EVENT_FAILED,
                payload: error.response,
            })
        }
    }
}
export const updateEvent = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.UPDATE_EVENT,
            payload: {}
        })


        const { error, response } = await makeAxiosCall("put", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.UPDATE_EVENT_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.UPDATE_EVENT_FAILED,
                payload: error.response,
            })
        }
    }
}

//------------------------------------------------------------------------------------------
export const getUserList = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.GET_USER_LIST,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("post", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.GET_USER_LIST_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.GET_USER_LIST_FAILED,
                payload: error.response,
            })
        }
    }
}
//-------------------------------------------------------------------------------
export const getUserProfile = (url_path) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.GET_USER_PROFILE,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("get", url_path)
        if (response) {
            dispatch({
                type: ACTION_TYPES.GET_USER_PROFILE_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.GET_USER_PROFILE_FAILED,
                payload: error.response,
            })
        }
    }
}
export const updateUserProfile = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.UPDATE_USER_PROFILE,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("put", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.UPDATE_USER_PROFILE_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.UPDATE_USER_PROFILE_FAILED,
                payload: error.response,
            })
        }
    }
}
export const importImage = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.IMPORT_IMAGE,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("post", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.IMPORT_IMAGE_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.IMPORT_IMAGE_FAILED,
                payload: error.response,
            })
        }
    }
}

export const updateUser = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.UPDATE_USER,
            payload: {}
        })


        const { error, response } = await makeAxiosCall("post", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.UPDATE_USER_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.UPDATE_USER_FAILED,
                payload: error.response,
            })
        }
    }
}


export const getPolicyList = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.GET_POLICY,
            payload: {}
        })
        const { error, response } = await makeAxiosCall("post", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.GET_POLICY_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.GET_POLICY_FAILED,
                payload: error.response,
            })
        }
    }
}

export const updatePolicy = (url_path, data) => {
    return async dispatch => {
        dispatch({
            type: ACTION_TYPES.UPDATE_POLICY,
            payload: {}
        })


        const { error, response } = await makeAxiosCall("put", url_path, data)
        if (response) {
            dispatch({
                type: ACTION_TYPES.UPDATE_POLICY_SUCCESS,
                payload: response.data,
            })
        }
        if (error) {
            dispatch({
                type: ACTION_TYPES.UPDATE_POLICY_FAILED,
                payload: error.response,
            })
        }
    }
}
