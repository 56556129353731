
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAILED = "GET_USER_PROFILE_FAILED"

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS"
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED"


export const IMPORT_IMAGE = "IMPORT_IMAGE"
export const IMPORT_IMAGE_SUCCESS = "IMPORT_IMAGE_SUCCESS"
export const IMPORT_IMAGE_FAILED = "IMPORT_IMAGE_FAILED"


export const GET_EVENT = "GET_EVENT"
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS"
export const GET_EVENT_FAILED = "GET_EVENT_FAILED"

export const ADD_EVENT = "ADD_EVENT"
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS"
export const ADD_EVENT_FAILED = "ADD_EVENT_FAILED"

export const UPDATE_EVENT = "UPDATE_EVENT"
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS"
export const UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED"

export const GET_USER_LIST = "GET_USER_LIST"
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS"
export const GET_USER_LIST_FAILED = "GET_USER_LIST_FAILED"


export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED"




export const GET_POLICY = "GET_POLICY"
export const GET_POLICY_SUCCESS = "GET_POLICY_SUCCESS"
export const GET_POLICY_FAILED = "GET_POLICY_FAILED"



export const UPDATE_POLICY = "UPDATE_POLICY"
export const UPDATE_POLICY_SUCCESS = "UPDATE_POLICY_SUCCESS"
export const UPDATE_POLICY_FAILED = "UPDATE_POLICY_FAILED"

